
  import Vue from 'vue';
  import PwaPracticeGame from "@/components/competitions/PwaPracticeGame.vue";
  import pageMixin from "@/mixins/page";

  export default Vue.extend({
    name: "PracticeGame",
    mixins: [pageMixin],
    components: {
      PwaPracticeGame,
    },
    props: {
      gameAlias: {
        type: String,
        required: true,
      }
    },
  });
